import { useState } from "react"
import SearchGood from "./searchGood/SearchGood";
import SearchBad from "./SearchBad/SearchBad";

const Search = () =>{

    let nameSrb = "Damjan Belojević";

    let nameEng = "Damjan Belojevic";

    const [inputValue, setInputValue] = useState("Damjan Belojević");

    const [successSearch, setSuccessSearch] = useState(true);


    const handleInputChange = (event) => {

        let current_value = event.target.value;

        setInputValue(current_value);

        if(nameSrb.toUpperCase().startsWith(current_value.toUpperCase()) || nameEng.toUpperCase().startsWith(current_value.toUpperCase())){
            setSuccessSearch(true);
        }else{
            setSuccessSearch(false);
        }
    }

    const handleKeyPress = (event) =>{
        if (event.key === 'Enter') {
            searchName();
        }
    }

    const searchName = () => {
        window.location.reload();
    }

    const showDropdown = () => {
        document.getElementsByClassName("searchResults")[0].style.opacity = "1";
        document.getElementsByClassName("searchResults")[0].style.display = "block";
    }

    const removeDropdown = () => {
        document.getElementsByClassName("searchResults")[0].style.opacity = "0";
        setTimeout(()=>{
            document.getElementsByClassName("searchResults")[0].style.display = "none";
        }, 300)
        
    }

    return (
        <div className="search">
            <div id="search_button" onClick={searchName}>
                <span className="fa-solid fa-magnifying-glass"></span>
            </div>
            <input type="text"
                   className="search_input" 
                   value={inputValue}
                   onChange={handleInputChange}
                   onKeyUp ={handleKeyPress}
                   onFocus={showDropdown}
                   onBlur={removeDropdown}
            />
            <div className="searchResults">
            <div>

            </div>
                { successSearch ? <SearchGood/> : <SearchBad/> }
            </div>
        </div>
    )
}

export default Search;