


const Skill = (props) =>{


    return (
        
            <div className="skill_wrapper">
                <p className="skill_title">{props.skill}</p>
                <div className="skill_ver">
                    <div className="skill_check_img">
                        <img src="img/pass.webp" alt="check" />
                    </div>
                    <p className="skill_pass_text">Passed Dambel Skill Assessment</p>
                </div>
                <p className="skill_endorsements"><span className="fa-solid fa-user-group"></span> <span>{props.endorsements}</span> endorsements</p>
                
            </div>

    )
}

export default Skill;