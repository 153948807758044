import { useNavigate } from 'react-router-dom';

const SearchGood = () => {

    const navigate = useNavigate();
    
    const searchName = () => {
        navigate('/')
    }

    return (
        <div>
            <div className="search_profile_wrapper" onClick={searchName}>
                <div className="search_profile_img">
                    <img src="/img/profile.webp" alt="Profile" />
                </div>
                <div className="serach_profile_info">
                    <p className="search_info_name">Damjan Belojević</p>
                    <p className="search_info_work">Software developer</p>  
                </div>
            </div>
            <p className="good_no_results">No More Results</p>
        </div>  
       
    )
}

export default SearchGood;