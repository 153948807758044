

const Info = ({openInfo}) => {

    return (
        <div className="settings_wrapper info_wrapper">
            <div className='settings_block'>
                <div className='settings_header'>
                   <div>
                        <p className='popup_title'>Contact Info</p>
                   </div>
                   <div>
                        <button className='close_popup' onClick={openInfo}><span className="fa-solid fa-xmark"></span></button>
                   </div>
                </div>


                <div className='settings_content'>
                    <div className='info_content_element'>
                        <div className="inf_content_wrapper">
                            <div className="inf_icon">
                                <span className="fa-solid fa-location-dot info_icon"></span>
                            </div>
                            <div className="inf_title">
                                <span className="info_title">Location</span>
                            </div>
                        </div>
                        <p className="info_value">Belgrade, Serbia</p>
                    </div>

                    <div className='info_content_element'>
                        <div className="inf_content_wrapper">
                            <div className="inf_icon">
                                <span className="fa-brands fa-linkedin info_icon"></span>
                            </div>
                            <div className="inf_title">
                                <span className="info_title">Linkedin</span>
                            </div>
                        </div>
                        <p className="info_value"><a className="icon_link" href="https://www.linkedin.com/in/damjan-belojevi%C4%87-375ba8161/" rel="noreferrer" target="_blank">linkedin.com/in/damjan-belojević-375ba8161/</a></p>
                    </div>

                    <div className='info_content_element'>
                        <div className="inf_content_wrapper">
                            <div className="inf_icon">
                                <span className="fa-solid fa-envelope info_icon"></span>
                            </div>
                            <div className="inf_title">
                                <span className="info_title">Email</span>
                            </div>
                        </div>
                        <p className="info_value"><a className="icon_link" href="mailto:belojevicdamjan&#64;gmail.com" rel="noreferrer" target="_blank">belojevicdamjan&#64;gmail.com</a></p>
                    </div>

                    <div className='info_content_element'>
                        <div className="inf_content_wrapper">
                            <div className="inf_icon">
                                <span className="fa-brands fa-github info_icon"></span>
                            </div>
                            <div className="inf_title">
                                <span className="info_title">GitHub</span>
                            </div>
                        </div>
                        <p className="info_value"><a className="icon_link" href="https://github.com/Damjan9898" rel="noreferrer" target="_blank">github.com/Damjan9898</a></p>
                    </div>




                    
                </div>
                
    
            </div>
            
        </div>
    )
};

export default Info;