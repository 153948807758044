import {  useContext, useEffect } from "react";
import { ToastMessageContext } from "../../../../App";




const Cover = ({openCover, changeCover}) => {


    const {successToastCall} = useContext(ToastMessageContext);

    

    const selectedCover = (index) =>{

        document.querySelectorAll(".library_element").forEach((element, i) => {
            if(index === i){
                element.style.border = "2px solid #34b8ff";
            }else{
                element.style.border = "none";
            }
        });

        document.getElementById("current_image").src = document.querySelectorAll(".library_element img")[index].getAttribute("src");
    }

    const resetCover = () =>{
        document.getElementById("current_image").src = "img/cover1.svg";
        changeCover("img/cover1.svg");
        openCover();
        successToastCall("Default cover image", "success");
    }

    const applyCover = () =>{
        changeCover(document.getElementById("current_image").getAttribute("src"));
        openCover();
        successToastCall("Cover image updated", "success");
    }

    useEffect(()=>{

        switch (localStorage.getItem("cover")) {
            
            case "img/cover1.svg":
                selectedCover(0)
                break;
            case "img/cover2.svg":
                selectedCover(1)
                break;
            case "img/cover3.svg":
                selectedCover(2)
                break;
            default:
                selectedCover(0)
                break;
        }
    }, [])

    return (
        <div className="settings_wrapper cover_wrapper">
            <div className='settings_block cover_block'>
                <div className='settings_header'>
                   <div>
                        <p className='popup_title'>Background photo</p>
                   </div>
                   <div>
                        <button className='close_popup' onClick={openCover}><span className="fa-solid fa-xmark"></span></button>
                   </div>
                </div>

                <div className="cover_body">

                    <p className="cover_title">Photo Preview</p>

                    <div className="current_cover">
                        <img src="" alt="Profile cover background" className="cover_image" id="current_image"/>
                    </div>

                    <p className="cover_title">Photo Library</p>

                    <div className="cover_library">
                        <div className="library_element" onClick={()=>selectedCover(0)}>
                            <img src="img/cover1.svg" alt="Profile cover background" className="cover_image"/>
                        </div>
                        <div className="library_element" onClick={()=>selectedCover(1)}> 
                            <img src="img/cover2.svg" alt="Profile cover background" className="cover_image"/>
                        </div>
                        <div className="library_element" onClick={()=>selectedCover(2)}>
                            <img src="img/cover3.svg" alt="Profile cover background" className="cover_image"/>
                        </div>
                    </div>

                    <hr className="cover_hr"/>

                    <div className="cover_buttons">
                        <div className="cover_button">
                            <button className="c_button cover_reset" onClick={resetCover}>Reset</button>
                        </div>
                        <div className="cover_button cover_second_button">
                            <button className="c_button cover_apply" onClick={applyCover}>Apply Changes</button>
                        </div>
                    </div>
                    
                </div>    
    
            </div>
            
        </div>
    )
}

export default Cover;