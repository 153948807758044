import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useState } from 'react';
import {  RemoveDesktopNotification, ShowChatContext, openNotificationContext } from '../../../App';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Nav = forwardRef(({ remFanRef, ...props }, ref) => {
    

    useImperativeHandle(ref, () => ({
        enableDisableNotification: (enable) => {
            enableDisableNotification(enable);
        }
    }));

    useImperativeHandle(remFanRef, () => ({
        removeDeskNotNumberFunCall: (enable) => {
            removeDeskNotNumberFunCall(enable);
        }
    }));

    const enableDisableNotification = (enable) =>{
        if(enable === true){
            enableNot();
        }else{
            disableNot();
        }
        
    }


    const removeDeskNotNumberFunCall = (enable) =>{
        if(enable === true){
            showNotificationNumber();
        }else{
            document.getElementById("notification_number").style.display = "none";
        }
        
    }

    const disableNot = () =>{
        document.getElementById("notification_number").style.display = "none";
        document.getElementsByClassName("not_hover")[0].style.color = "var(--disable_not_button)";
    }
    
    const enableNot = () =>{
        showNotificationNumber();
        document.getElementsByClassName("not_hover")[0].style.color = "#888888";
    }

    setTimeout(()=>{
        if(localStorage.getItem("notificationOn") !== "true"){
            disableNot()
        }
    },10)

    const [removeDeskNot, setRemoveDeskNot] = useContext(RemoveDesktopNotification);

    const [openNotifications, setOpenNotifications] = useContext(openNotificationContext);

    const [numberOfViews, setNumberOfViews] = useState(0);


    const showNotificationNumber = useCallback(() => {
        if(!localStorage.getItem("clear_not")){
            document.getElementById("notification_number").style.display = "flex";
            document.getElementById("notification_number").textContent = 1;
            setRemoveDeskNot(true);
            
        }else{
            document.getElementById("notification_number").style.display = "none";
            setRemoveDeskNot(false);
            
        }
    }, [setRemoveDeskNot]);


    


    useEffect(()=>{


        
        const showPageView = ()=>{
            //Add new view
            addNewView().then(dateValue => {
                localStorage.setItem("pageview_date", dateValue);
            }).catch(error => {
                console.error('Error handling the returned value:', error);
            });

            //Update the view count
            countTodayViews().then(totalViewsToday => {
                if(!localStorage.getItem("clear_not") && removeDeskNot){
                    document.getElementById("not_day_value").textContent = totalViewsToday;
                }
            }).catch(error => {
                console.error('Error handling the returned value:', error);
            });

        }

            if(localStorage.getItem("pageview_date")){

                //Show notification number ( also checks if clear notification was clicked )
                showNotificationNumber();
                
                //Check the date of last notification. If it's for yesterday then add new notification
                let not_date = new Date(localStorage.getItem("pageview_date")).getTime();

                // Get today's date at midnight
                const today = new Date();
                today.setHours(0, 0, 0, 0);

                const dateToCompare = new Date(not_date);
                dateToCompare.setHours(0, 0, 0, 0);

                if (dateToCompare.getTime() !== today.getTime()) {
                    localStorage.removeItem("clear_not");
                    localStorage.removeItem("pageview_date")
                    showPageView();
                    removeDeskNotNumberFunCall(true);
                }
            }else{
                setTimeout(()=>{
                    //Remove notification if clear notification is clicked on mobile
                    setRemoveDeskNot(true);
                    //Add new View
                    addNewView().then(dateValue => {
                        localStorage.setItem("pageview_date", dateValue);
                    }).catch(error => {
                        console.error('Error handling the returned value:', error);
                    });
                    
                    if(localStorage.getItem("notificationOn") === "true"){
                        showNotificationNumber();
                    }
                
                }, 10000)
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setRemoveDeskNot, showNotificationNumber])




    const showNotification = () =>{
        if(localStorage.getItem("notificationOn") === "true"){
            //Show notification block
            setOpenNotifications(true);

            //Show the number of views in a notification element
            countTodayViews().then(totalViewsToday => {
                if(!localStorage.getItem("clear_not") && removeDeskNot){
                    setNumberOfViews(totalViewsToday);
                }
            }).catch(error => {
                console.error('Error handling the returned value:', error);
            });
        }
    }

    

    const deleteNotifications = () =>{
        document.getElementById("notification_number").style.display = "none";
        localStorage.setItem("clear_not", "true");
        setRemoveDeskNot(false);
    }


    const closeNotifications = (event) =>{
        event.stopPropagation();
        setOpenNotifications(false)
    }

    /*NOTIFICATION TEST START*/
    const addNewView = async () =>{
        try {
            const response = await fetch('https://backend.dambel.dev/api/');
            const data = await response.json();
            return data.date === null ? new Date() : data.date;
        } catch (error) {
            console.error('Error fetching view count:', error);
        }
    }

    


    const countTodayViews = async () =>{
        try {
            const response = await fetch('https://backend.dambel.dev/api/counttoday');
            const data = await response.json();
            return data.totalViewsToday === null ? 1 : data.totalViewsToday;
        } catch (error) {
            console.error('Error fetching view count:', error);
        }
    }

    //Save Number of Views
    countTodayViews().then(totalViewsToday => {
        if(!localStorage.getItem("clear_not") && removeDeskNot){
            setNumberOfViews(totalViewsToday);
            localStorage.setItem("todays_not", totalViewsToday)
        }
    }).catch(error => {
        console.error('Error handling the returned value:', error);
    });
   



    /*NOTIFICATION TEST END*/


    const {showChat} = useContext(ShowChatContext)

    const location = useLocation();

    const navigate = useNavigate();

    const changeRoute = () =>{
        if(location.pathname === '/'){
            navigate('/#projects')
        }else if(location.pathname === '/work'){
            scrollToTop();
        }else{
            navigate('/work')
        }
    }

    const changeRouteExp = () =>{
        navigate('/#experience')
    }

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    
    

    

   

    return(
        <div className="navbar_wrapper">
            <nav>
                <div  onClick={() => changeRouteExp()} href="#experience" className="nav_link">
                    <div className="nav_element">
                        
                            <div className="nav_icon">
                                <span className="fa-solid fa-suitcase"></span>    
                            </div>
                            <div className="nav_text">
                                Experience
                            </div>
                    </div>
                </div>
                <div onClick={() => changeRoute()}  className="nav_link">
                    <div className="nav_element">
                        
                            <div className="nav_icon">
                                <span className="fa-solid fa-folder-closed"></span>    
                            </div>
                            <div className="nav_text">
                                Projects
                            </div>
                    </div>
                </div>
                <div className="nav_link nav_lint_not">
                    <div className="nav_element not_element" onClick={() => showNotification()}>

                            <div className='not_hover'>
                                <div className="nav_icon">
                                    <span className="fa-solid fa-bell"></span>
                                </div>
                                <div className="nav_text">
                                    Notifications
                                </div>
                            </div>
                            
                            <div id="notification_number">
                                1
                            </div>

                            {openNotifications &&
                            
                            <div className='notification_block' id="notification_block" >

                            
                            {removeDeskNot === true?
                                <div className='notification_content'>
                                    <div className="close_not" onClick={ closeNotifications}>
                                        <span className="fa-regular fa-circle-xmark"></span>
                                    </div>
                                    <div className='not_wrapper'>
                                        <div className='not_profile'>
                                            <img src="/img/profile.webp" alt="Damjan profile"/>
                                        </div>
                                        <div className='not_cont_wrapper'>
                                            <p className='not_content_text'>Somebody viewed your profile!</p>
                                            <p className='not_content_text'>You got <b><span id="not_day_value">{numberOfViews}</span> new view(s)</b> today</p>
                                            
                                        </div>
                                    </div>

                                    <hr className='not_hr'/>
                                    
                                    

                                    <button className='clear_not_button' onClick={() => deleteNotifications()}>Clear Notifications</button>
                                </div>

                                :

                                <div className='no_new_wrapper'>
                                    <div className="close_not" onClick={ closeNotifications}>
                                        <span className="fa-regular fa-circle-xmark"></span>
                                    </div>
                                    <p className='no_not_content'>No new notifications</p>
                                </div>
                            
                            }
                                
                            </div>
                        }
                             
                    </div>
                </div>
                <div className="nav_link" onClick={showChat}>
                    <div className="nav_element">
                        
                            <div className="nav_icon">
                                <span className="fa-solid fa-comment-dots"></span>
                            </div>
                            <div className="nav_text">
                                Messaging
                            </div>
                    </div>
                </div>
            </nav>
            
        </div>
    )
});

export default Nav;