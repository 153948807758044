import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { ShowChatContext, ShowFaqContext, ShowNotContext, ShowSettingsContext } from '../../../../App';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';


const ProfileInfo = forwardRef(({ removeProfileDropdown, ...props }, ref) => {

    const [showNotNumber, setShowNotNumber] = useState(false);

    const [showNot, setShowNot] = useContext(ShowNotContext);

    const showHideNot = () =>{
        if(localStorage.getItem("notificationOn") === "true"){
            setShowNot(!showNot);
        }
    }

    useImperativeHandle(ref, () => ({
        enableDisableNotificationMobile: (enable) => {
            enableDisableNotificationMobile(enable);
        }
    }));

    const enableDisableNotificationMobile = (enable) =>{
        if(enable === true){
            enableNot();
        }else{
            disableNot();
        }
        
    }

    const disableNot = () =>{
        setShowNotNumber(false)
        document.getElementsByClassName("pf_not_link")[0].style.color = "var(--disable_not_button)";
    }
    
    const enableNot = () =>{
        showNotificationNumber();
        document.getElementsByClassName("pf_not_link")[0].style.color = "var(--profile_drop_content)";
    }

    setTimeout(()=>{
        if(localStorage.getItem("notificationOn") !== "true"){
            disableNot()
        }
    },1000)

   
    useEffect(()=>{

            if(localStorage.getItem("pageview_date")){
                showNotificationNumber();
            }else{
                setTimeout(()=>{showNotificationNumber()}, 10000)
            }
        
    },)


    

    const showNotificationNumber = () =>{
        
        if(!localStorage.getItem("clear_not") && localStorage.getItem("notificationOn") === "true"){
            setShowNotNumber(true) 
        }else{
            setShowNotNumber(false)
        }
    }



    /* End of notifications */


    const [showSettings, setShowSettings] = useContext(ShowSettingsContext);

    const [showFaq, setShowFaq] = useContext(ShowFaqContext);

    const {showChat} = useContext(ShowChatContext);

    const navigate = useNavigate();

    const showSettingsElement = () =>{
        setShowSettings(!showSettings);
        removeProfileDropdown();
    }

    const showFaqElement = () =>{
        setShowFaq(!showFaq);
        removeProfileDropdown();
    }

    const showChatElement = () =>{
        showChat();
        removeProfileDropdown();
    }

    let stopPropagation = (event) => {
        event.stopPropagation();
    }

    const location = useLocation();

    const changeRoute = () =>{
        if(location.pathname === '/'){
            window.location.href = '/#projects';
            removeProfileDropdown();
        }else if(location.pathname === '/work'){
            scrollToTop();
            removeProfileDropdown();
        }else{
            navigate('/work')
            removeProfileDropdown();
        }
    }


    const changeRouteExp = () =>{
        window.location.href = '/#experience';
        removeProfileDropdown();
    }

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    const showProfile = () =>{
        navigate('/')
        removeProfileDropdown();
    }

    return (
        <div className="profile_dropdown" onClick={stopPropagation}>
            <div className="profile_drop_me">
                <div className="profile_drop_image">
                    <img src="/img/profile.webp" alt="Profile" />
                </div>
                <div className="profile_drop_info">
                    <p className="profile_drop_name">Damjan Belojević</p>
                    <p className="profile_drop_work">Software developer <br/>at FlowCandy</p>  
                </div>
            </div>
            <button className="view_profile_button" onClick={ ()=> showProfile()}>View Profile</button>

            <div className="mobile_nav">
                <hr className="horizontal_profile"/>

                <div className="prof_drop_element">
                    <p className="pd_title">Portfolio</p>
                    <div className="pd_links">
                        <button onClick={() => changeRouteExp()} className="pd_link"><span className="fa-solid fa-suitcase"></span> Experience</button>
                        <button onClick={() => changeRoute()} className="pd_link"><span className="fa-solid fa-folder-closed"></span> Projects</button>
                        <button onClick={() => showHideNot()} className="pd_link pf_not_link"><span className="fa-solid fa-bell move_bel"></span> Notifications {showNotNumber && <span id="profile_not">1</span>}</button>
                        <button className="pd_link" onClick={showChatElement}><span className="fa-solid fa-comment-dots"></span> Messaging</button>
                    </div>
                </div>
            </div>
            

            <hr className="horizontal_profile"/>

            <div className="prof_drop_element">
                <p className="pd_title">Account</p>
                <div className="pd_links">
                    <button className="pd_link" onClick={showSettingsElement}>Settings</button>
                    <button className="pd_link" onClick={showFaqElement}>FAQ</button>
                    <button className="pd_link" onClick={showChatElement}>Help</button>
                </div>
            </div>

            <hr className="horizontal_profile last_hr"/>

            <div className="prof_drop_element">
                <div className="signuout_wrapper">
                    <a href="/login" className="pd_link signout">Sign Out</a>
                </div>
                
            </div>
            
        </div>
    )
});

export default ProfileInfo;