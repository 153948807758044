import { forwardRef, useEffect } from "react";
import About from "./about/About"
import MainProfile from "./main_profile/MainProfile"
import Experience from "./experience/Experience";
import Projects from "./projects/Projects";
import Skills from "./skills/Skills";
import Languages from "./languages/Langugages";
import Interests from "./interests/Interests";
import { useLocation } from 'react-router-dom';

const Main  = forwardRef((props, ref) => {

    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/' && window.location.hash === '#experience') {
            setTimeout(()=>document.getElementById('experience')?.scrollIntoView({ behavior: 'smooth' }), 200)
            
        }
    }, [location]);

    return(
        <div className="main_body_wrapper">

                <div>
                    <MainProfile ref={ref}/>
                    <About/>
                    <Experience/>
                </div>


                <Projects showall={false}/>

                <Skills showall={false}/>
       

                <div>
                    <Languages/>
                    <Interests/>
                </div>
          
            
        </div>
    )
});

export default Main