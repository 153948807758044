import { useEffect, useState } from "react";


const About = () =>{

    const about_content = "Hey there! I'm a software developer with a twist. My story began in molecular biology, but I soon discovered my true calling in the world of code. Swapping petri dishes for programming, I graduated from Singidunum University in computer science and haven't looked back. From HTML5 to NodeJS, my journey has been a deep dive into both front-end and back-end development, and I love every bit of it.<br/><br/>At FlowCandy, I'm the go-to person for crafting engaging email flows with Klaviyo and spicing up our website using Webflow. My day-to-day involves a mix of creativity and problem-solving, ensuring everything runs smoothly and looks great. Whether it's tweaking marketing tools or diving into JavaScript, there's always something exciting on my plate.<br/><br/>Why did I leave molecular biology, you ask? It's simple. I thrive in teamwork and lively environments - coding gives me that. It's more than a job; it's where my passion for tech and working with awesome people intersects. I'm all about turning ideas into reality and constantly learning new tricks of the trade.<br/><br/>Looking ahead, I'm excited about connecting with fellow tech enthusiasts and tackling innovative projects. My mission? To keep blending my love for coding with a spirit of collaboration, and to keep things light and fun along the way. Let's make something amazing together!&nbsp;"

    const substring_content = "Hey there! I'm a software developer with a twist. My story began in molecular biology, but I soon discovered my true calling in the world of code. Swapping petri dishes for programming, I graduated from Singidunum University in computer science and haven't looked back. From HTML5 to NodeJS, my journey has been a deep dive into both front-end and back-end development, and I love every bit of it.<br/><br/>At FlowCandy, I'm the go-to person for crafting engaging email flows with Klaviyo and spicing up our website using Webflow. My day-to-day involves a mix of creativity...&nbsp;"

    const [allContent, setAllContent] = useState(substring_content);


    const addContent = () =>{
        document.getElementById("about_content").innerHTML = allContent;
    }

    useEffect(()=>{
        addContent();
    })

   

    const toggleContent = () =>{
        if(allContent === about_content){
            setAllContent(substring_content)
        }else{
            setAllContent(about_content)
        }
       
    }

    return(
        <div className="main_section about_section">
            
            <div className="about_wrapper">
                <p className="section_title ab_title">About</p>

                <span className="about_content" id="about_content">
                
                </span>
                
                {allContent === substring_content && <button className="about_button" onClick={ toggleContent}>Show More</button> }
                    
                
            </div>

            
        </div>
    )
}   

export default About;