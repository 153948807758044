import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import Header from './components/header/Header';
import Settings from './components/header/profile/profileInfo/settings/Settings';
import Faq from './components/header/profile/profileInfo/faq/Faq';
import Chat from './components/chat/Chat';
import Main from './components/main/Main';
import SingleProject from './components/main/projects/singleproject/SingleProject';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Footer from './components/main/footer/Footer';
import Projects from './components/main/projects/Projects';
import Skills from './components/main/skills/Skills';
import Notifications from './components/header/profile/profileInfo/notifications/Notifications';
import Signin from './components/signin/Signin';
import Panel from './components/panel/Panel';
import Admin from './components/panel/admin/Admin';

// Create a Context
export const ShowSettingsContext = React.createContext(null);

// Create a Context
export const DarkModeThemeContext = React.createContext(null);

// Create a Context
export const turnOffNotificationsContext = React.createContext(null);

// Create a Context
export const ShowFaqContext = React.createContext(null);

// Create a Context
export const ShowChatContext = React.createContext(null);

// Create a Context
export const ShowAllProjectsContext = React.createContext(null);

// Create a Context
export const ToastMessageContext = React.createContext(null);

// Create a Context
export const openNotificationContext = React.createContext(null);

// Create a Context
export const enableNotificationContext = React.createContext(null);

// Create a Context
export const enableNotificationMobileContext = React.createContext(null);

// Create a Context
export const ShowNotContext = React.createContext(null);

// Create a Context
export const RemoveDesktopNotification = React.createContext(null);


// Create a Context
export const RemoveDeskNotNumberContext = React.createContext(null);



function App() {

  const [showSettings, setShowSettings] = useState(false);

  const [showFaq, setShowFaq] = useState(false);

  const [showNot, setShowNot] = useState(false);

  const [openNotifications, setOpenNotifications] = useState(false);

  const [removeDeskNot, setRemoveDeskNot] = useState(false);

  const location = window.location.pathname;

  const isLoginRoute = location === '/login';

  const isPanelRoute = location === '/panel';

  const isAdminRoute = location === '/panel/admin';


  useEffect(() => {

      const selectedTheme = localStorage.getItem("selectedTheme");

      const notificationOn = localStorage.getItem("notificationOn");
      
      if (selectedTheme) {
          if(selectedTheme === "dark"){
              setDarkMode();
          }else{
              setLightMode();
          }
      } else {
          setLightMode();
      }

      if(notificationOn){
        notificationOn === "true" ?  setNotificationOn() :  setNotificationOFF();
      }else{
        setNotificationOn();
      }



  }, []);

  const toggleTheme = (event) =>{
      event.target.checked ? setDarkMode() : setLightMode();
  }

 

  //Set the dark mode light theme
  const setDarkMode = () => {
      document.body.setAttribute("data-theme", "dark");

      if(document.getElementsByClassName("logo_img")[0]){
        document.getElementsByClassName("logo_img")[0].src = "/img/logo_dark.webp";
      }
     


      localStorage.setItem("selectedTheme", "dark");
  };

  const setLightMode = () =>{

      document.body.setAttribute("data-theme", "light");

      if(document.getElementsByClassName("logo_img")[0]){
        document.getElementsByClassName("logo_img")[0].src = "/img/logo.webp";
      }

      localStorage.setItem("selectedTheme", "light");
  }


  const toggleNotification = (event) =>{
    event.target.checked ? setNotificationOn() : setNotificationOFF();
  }

  const setNotificationOn = () =>{
    localStorage.setItem("notificationOn", "true");
  }

  const setNotificationOFF= () =>{
    localStorage.setItem("notificationOn", "false");
  }

  const chatRef = useRef();

  const showChat = ()=>{
    if (chatRef.current) {
      chatRef.current.openChat();
    }
  }


  const toastRef = useRef();


  const successToastCall = (message, success_code) =>{
    if (toastRef.current) {
      toastRef.current.successToast(message, success_code);
    }
  }

  const notificationRef = useRef();


  const enableDisableNotificationCall = (enable) =>{
    if (notificationRef.current) {
      notificationRef.current.enableDisableNotification(enable);
    }
  }

  const notificationMobileRef = useRef();


  const enableDisableNotificationCallMobile = (enable) =>{
    if (notificationMobileRef.current) {
      notificationMobileRef.current.enableDisableNotificationMobile(enable);
    }
  }

  const removeDeskfFunRef = useRef();

  const removeDeskNotNumberFunCall = (enable) =>{
    if (removeDeskfFunRef.current) {
      removeDeskfFunRef.current.removeDeskNotNumberFunCall(enable);
    }
  }


  const scrollSection = (section_id) =>{
    let section = document.querySelector(section_id);
    if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
        
    }
  }

  const backToProjects = () =>{
    setTimeout(() => scrollSection("#projects"), 200);
  }

  const backToSkills = () =>{
    setTimeout(() => scrollSection("#skills"), 200);
  }

  return (
    <Router>
    
    <RemoveDeskNotNumberContext.Provider value={removeDeskNotNumberFunCall}>
    <RemoveDesktopNotification.Provider value={[removeDeskNot, setRemoveDeskNot]}>
    <ShowNotContext.Provider value={[showNot, setShowNot]}>
    <enableNotificationMobileContext.Provider value={enableDisableNotificationCallMobile}>
    <enableNotificationContext.Provider value={enableDisableNotificationCall}>
        <openNotificationContext.Provider value={[openNotifications, setOpenNotifications]}>
          <ToastMessageContext.Provider value={{ successToastCall }}>
            <ShowChatContext.Provider value={{ showChat }}>
              <ShowFaqContext.Provider value={[showFaq, setShowFaq]}>
                <turnOffNotificationsContext.Provider value={{ toggleNotification }}>
                  <DarkModeThemeContext.Provider value={{ toggleTheme }}>
                    <ShowSettingsContext.Provider value={[showSettings, setShowSettings]}>
                      <div className="App">
                        {(!isLoginRoute && !isPanelRoute && !isAdminRoute) && <Header ref={toastRef} navRef={notificationRef} profRef={notificationMobileRef} remFanRef={removeDeskfFunRef}/>}
                        <Routes>
                          
                          <Route path="/" element={<Main />} />
                          <Route path="/work/:projectUrl" element={<SingleProject/>} />
                          <Route path="/work" element={<Projects showall={true} backToProjects={backToProjects}/>} />
                          <Route path="/skills" element={<Skills showall={true} backToSkills={backToSkills}/>} />
                          <Route path="/login" element={<Signin />} />
                          <Route path="/panel" element={<Panel />} />
                          <Route path="/panel/admin" element={<Admin />} />
                          <Route path="*" element={<Navigate to="/" />} />
                        </Routes>
                        <Footer/>
                        {showSettings && <Settings />}
                        {showFaq && <Faq />}
                        {showNot &&  <Notifications />}
                        
                        {(!isLoginRoute && !isPanelRoute && !isAdminRoute) &&<Chat ref={chatRef} />}
                      </div>
                    </ShowSettingsContext.Provider>
                  </DarkModeThemeContext.Provider>
                </turnOffNotificationsContext.Provider>
              </ShowFaqContext.Provider>
            </ShowChatContext.Provider>
          </ToastMessageContext.Provider>
          </openNotificationContext.Provider>
        </enableNotificationContext.Provider>
        </enableNotificationMobileContext.Provider>
        </ShowNotContext.Provider>
        </RemoveDesktopNotification.Provider>
        </RemoveDeskNotNumberContext.Provider>
    </Router>
  );
}

export default App;
