

const Experience = () =>{

    //Time at Flowcandy
    const start = new Date("2022-05-08");
    const now = new Date();
    const totalMonths = (now.getFullYear() - start.getFullYear()) * 12 + now.getMonth() - start.getMonth() + 1;
    const years = Math.floor(totalMonths / 12);
    const months = totalMonths % 12;
    const formattedDifference = `${years} yr ${months} mos`;


    return (
        <div className="main_section about_section relative_section">
            <div id="experience">

            </div>
            
            <div className="about_wrapper">
                <p className="section_title">Experience</p>

                <div className="experience_content" id="experience_content">
               
               
                    <div className="ex_main">
                        <div className="ex_logo_wrapper project_img_wrap">
                            <img src="img/fc-transparent.webp" alt="Flowcandy logo"/>
                        </div>
                        <div className="ex_main_info">
                            <a href="https://flowcandy.com/" target="_blank" rel="noreferrer" className="ex_title">FlowCandy</a>

                            <p className="ex_body_element ex_pos">Developer - Full Time</p>
                        
                            <p className="ex_title_info">May 2022 - Present · {formattedDifference}</p>
                        
                            <p className="ex_title_info">United States, Remote</p>
                        </div>

                    </div>

                    <div className="ex_body">

                        <p className="ex_body_element">
                            <b>Description: </b> Email developer (Klaviyo, Shopify, AMP), Web developer (HTML5, CSS3, Javascript, PHP, MySQL, Webflow), and Troubleshooter
                        </p>

                        <p className="ex_body_element">
                            <b>Skills: </b> PHP · AMP · Klaviyo · Webflow · Shopify · JavaScript · MySQL
                        </p>
                    </div>

                </div>
                
            </div>

            
        </div>
    )
}


export default Experience;