import { forwardRef, useContext, useState } from "react";
import ProfileInfo from "./profileInfo/ProfileInfo";
import { openNotificationContext } from "../../../App";

const Profile = forwardRef((props, ref) => {

    const [isToggled, setIsToggled] = useState(false);

    const [openNotifications, setOpenNotifications] = useContext(openNotificationContext);

    let ignore = openNotifications.toString();


    let showProfileDropdown = (event) =>{

        setOpenNotifications(false)

        if(isToggled === false){
            event.stopPropagation();
    
            document.getElementsByClassName("profile_arrow")[0].style.transform = "rotate(180deg)";
    
            document.getElementsByClassName("profile_dropdown")[0].style.opacity = "1";
    
            document.getElementsByClassName("profile_dropdown")[0].style.display = "block";

            setIsToggled(true);


        }else{
            removeProfileDropdown();
        }

       
    }

    window.addEventListener("click", ()=>{
        removeProfileDropdown();
    })

    let removeProfileDropdown = () =>{

        document.getElementsByClassName("profile_arrow")[0].style.transform = "rotate(0deg)";

        document.getElementsByClassName("profile_dropdown")[0].style.opacity = "0";
        setTimeout(()=>{
            document.getElementsByClassName("profile_dropdown")[0].style.display = "none";
        }, 100)

        setIsToggled(false);
    }

    



    return (
        <div className="profile_wrapper" onClick={showProfileDropdown}>
            <div className="profile_image" >
                <img src="/img/profile.webp" alt="Profile" className={ignore}/>
            </div>
            <div className="profile_arrow">
                <span className="fa-solid fa-caret-down profile_arrow"></span>
            </div>
                <ProfileInfo  removeProfileDropdown={removeProfileDropdown} ref={ref}/>

        </div>
    )
});

export default Profile;