import { useContext, useState } from "react";
import { ShowFaqContext } from "../../../../../App";
import Answer from "./answer/Answer";


const Faq = () =>{


    const [showFaq, setShowFaq] = useContext(ShowFaqContext);

    const [openFaq, setOpenFaq] = useState(null);

    const toggleFaq = (faqIndex) => {
        if (openFaq === faqIndex) {
          setOpenFaq(null); // Close if the same FAQ item is clicked again
        } else {
          setOpenFaq(faqIndex); // Open the clicked FAQ item
        }
      };

    const answer1 = "This website was built using React.js for the frontend, with a bit of Node.js on the backend. For data storage, I used a MySQL database.";

    const answer2 = "I started coding 5 years ago, and I'm doing it professionally for about 3 and a half years now.";

    const answer3 = "Yes, I have a bachelor's degree in Computer science. It was a major help for me because I had a lot of amazing professors that guided me to the right path in my profession.";

    const answer4 = "The website is made in React.js, but all components are custom made by me. I didn't used any additional libraries for animations and other elements.";

    const showFaqElement = () =>{
        setShowFaq(!showFaq);
    }



    return (
        <div className="settings_wrapper">
            <div className='settings_block'>
                <div className='settings_header'>
                   <div>
                        <p className='popup_title'>FAQ</p>
                   </div>
                   <div>
                        <button className='close_popup' onClick={showFaqElement}><span className="fa-solid fa-xmark"></span></button>
                   </div>
                </div>


                <div className='settings_content'>
                    <div className='settings_content_element'>

                        <div className='setting_content_block'>
                            <div className='setting_content_row faq_row' onClick={() => toggleFaq(1)}>
                                <div className="faq_flex">
                                    <p className='settings_row_title faq_title'>Which languages did you use for this website?</p>
                                    <p className='settings_row_text'><span className="fa-solid fa-caret-down"></span></p>
                                </div>
                                
                                {openFaq === 1 && <Answer content={answer1}/>}
                            </div>

                            <hr className='setting_content_hr'/>

                            <div className='setting_content_row faq_row' onClick={() => toggleFaq(2)}>
                                <div className="faq_flex">
                                    <p className='settings_row_title faq_title'>How many years of programming experience do you have?</p>
                                    <p className='settings_row_text'><span className="fa-solid fa-caret-down"></span></p>
                                </div>
                                
                                {openFaq === 2 && <Answer content={answer2}/>}
                            </div>

                            <hr className='setting_content_hr'/>

                            <div className='setting_content_row faq_row' onClick={() => toggleFaq(3)}>
                                <div className="faq_flex">
                                    <p className='settings_row_title faq_title'>What is your educational background in programming? Do you hold a degree, or are you self-taught?</p>
                                    <p className='settings_row_text'><span className="fa-solid fa-caret-down"></span></p>
                                </div>
                                
                                {openFaq === 3 && <Answer content={answer3}/>}
                            </div>

                            <hr className='setting_content_hr'/>

                            <div className='setting_content_row faq_row' onClick={() => toggleFaq(4)}>
                                <div className="faq_flex">
                                    <p className='settings_row_title faq_title'>Can you highlight any special libraries or frameworks used in building this website?</p>
                                    <p className='settings_row_text'><span className="fa-solid fa-caret-down"></span></p>
                                </div>
                                
                                {openFaq === 4 && <Answer content={answer4}/>}
                            </div>

                            
                        </div>
                    </div>
                </div>
                
    
            </div>
            
        </div>
    )
}

export default Faq