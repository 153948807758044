
import React, { useImperativeHandle, forwardRef, useContext } from 'react';
import { ToastMessageContext } from '../../App';


const Chat = forwardRef((props, ref) => {


    useImperativeHandle(ref, () => ({
        openChat: () => {
            openMessage();
        }
    }));

    

        const toggleMessages = ()=>{
            
            document.getElementsByClassName("chat_wrapper")[0].style.height === "40px" ? openMessage() : closeMessage(); 
        }

        const openMessage = () =>{
            document.getElementsByClassName("chat_wrapper")[0].style.height = "400px";
            document.getElementsByClassName("arrow_chat")[0].style.transform = "rotate(180deg)";
        }

        const closeMessage = () =>{
            document.getElementsByClassName("chat_wrapper")[0].style.height="40px"; 
            document.getElementsByClassName("arrow_chat")[0].style.transform = "rotate(0deg)";
        }

        //Form submission
        const {successToastCall} = useContext(ToastMessageContext);

    
        function handleSubmit() {


            const isFieldEmpty = (filed_selector) =>{
                if(filed_selector.value){
                    filed_selector.style.border = "1px solid var(--message_input_border)";
                }else{
                    filed_selector.style.border = "1px solid red";
                }
            }

            function validateEmail(email) {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return emailRegex.test(email);
            }

            const subject = document.getElementById('subject_input').value;
            const email = document.getElementById('email_input').value;
            const message = document.getElementById('message_text').value;
            const honeypot = document.getElementById('honeypot').value;
            

            const subject_input = document.getElementById('subject_input');
            const email_input = document.getElementById('email_input');
            const message_input = document.getElementById('message_text');

            const clearInputs = () =>{
                document.getElementById('subject_input').value = "";
                document.getElementById('email_input').value = "";
                document.getElementById('message_text').value = "";
            }

            if(!honeypot){
                if(subject && email && message){
                    if(validateEmail(email)){

                        if(subject.length <= 191){
                            if(message.length <= 1000){
                                fetch('https://backend.dambel.dev/api/submit-message', {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                    body: JSON.stringify({ subject, email, message }),
                                    })
                                    .then(response => {
                                        if (!response.ok) {
                                          throw response;
                                        }
                                        clearInputs();
                                        successToastCall("Your message is sent!", "success");
                                        return response.json();
                                    })
                                    .catch(async (errorResponse) => {
                                        const error = await errorResponse.json();
                                        successToastCall(error.error, "error");
                                    });
                            }else{
                                //Message too long
                                successToastCall("The maximum length of the message is 1000 characters", "error");
                            }
                            
                        }else{
                            //Subject line too long
                            successToastCall("Subject line too long", "error");
                        }
                    }else{
                        //Email format is not good
                        successToastCall("Email format is invalid", "error");
                    }
                }else{
                    //Some of the fields are empty
                    successToastCall("All message fields are required", "error");
                    isFieldEmpty(subject_input)
                    isFieldEmpty(email_input)
                    isFieldEmpty(message_input)
                }
            }else{
                //First step prevention from bots
                successToastCall("Bye Bye bot", "error");
            }

            
          
          }

          const handleInputChange = (event) => {
            event.target.style.border = "1px solid var(--message_input_border)";
        }

        


    return (
        <div className="chat_wrapper" style={{ height: "40px" }}>
            <div className='chat_header' onClick={toggleMessages}>
                <div className='chat_profile'>
                    <div className='cp_image_wrapper'>
                        <img src="/img/profile.webp" alt="Profile" className='cp_image'/>
                    </div>
                    <div className='online_circle'>

                    </div>
                </div>
                <div className='chat_messaging'>
                    <p>Messaging</p>
                </div>
                <div className='chat_opn_close'>
                    <span className="fa-solid fa-chevron-up arrow_chat"></span>
                </div>
            </div>
            <div className='chat_input_wrapper'>
                <p className='message_title'>Subject</p>
                <input type='text' className='message_input' id="subject_input" name="subject_input" required onChange={handleInputChange} maxLength="191"/>

                <p className='message_title'>Your email</p>
                <input type='email' className='message_input' id="email_input" name="subject_input" required onChange={handleInputChange}/>

                <input type='text' id="honeypot" name="honeypot"/>

                <p className='message_title'>Message</p>
                <textarea id='message_text' required onChange={handleInputChange} maxLength="1000"></textarea>

                <button type='button' className='message_send' onClick={handleSubmit} >Send</button>
            </div>
        </div>
    )
});

export default Chat;