
import React, { useContext, useEffect } from 'react';
import { ShowSettingsContext, turnOffNotificationsContext, DarkModeThemeContext, enableNotificationContext, enableNotificationMobileContext } from '../../../../../App';

const Settings = () =>{


    const [showSettings, setShowSettings] = useContext(ShowSettingsContext);

    const {toggleTheme} = useContext(DarkModeThemeContext);

    const {toggleNotification} = useContext(turnOffNotificationsContext);

    const enableDisableNotificationCall = useContext(enableNotificationContext);

    const enableDisableNotificationCallMobile = useContext(enableNotificationMobileContext);

    const changeNotificationSettings = (event) =>{
        toggleNotification(event);
        enableDisableNotificationCall(event.target.checked);
        enableDisableNotificationCallMobile(event.target.checked);
    }


    useEffect(() => {
        document.getElementById("dark_switch").checked = localStorage.getItem("selectedTheme") === "dark";  
        document.getElementById("notification_switch").checked = localStorage.getItem("notificationOn") === "true";
    }, []);


    const showSettingsElement = () =>{
        setShowSettings(!showSettings);
    }

    return(
        <div className="settings_wrapper">
            <div className='settings_block'>
                <div className='settings_header'>
                   <div>
                        <p className='popup_title'>Settings</p>
                   </div>
                   <div>
                        <button className='close_popup' onClick={showSettingsElement}><span className="fa-solid fa-xmark"></span></button>
                   </div>
                </div>
                <div className='settings_content'>
                    <div className='settings_content_element'>
                        <p className='setting_content_title'>Platform Infirmation</p>

                        <div className='setting_content_block'>
                            <div className='setting_content_row'>
                                <p className='settings_row_title'>Developer</p>
                                <p className='settings_row_text'>Damjan Belojević</p>
                            </div>

                            <div className='setting_content_row'>
                                <p className='settings_row_title'>Technologies</p>
                                <p className='settings_row_text'>React.js / Node.js</p>
                            </div>

                            <hr className='setting_content_hr'/>
                        </div>
                    </div>
                    <div className='settings_content_element'>
                        <p className='setting_content_title'>Account preferences</p>

                        <div className='setting_content_block'>
                            <div className='setting_content_row'>
                                <p className='settings_row_title'>Dark Mode</p>
                                <label className="switch">
                                    <input type="checkbox" onChange={toggleTheme} id="dark_switch"/>
                                    <span className="slider round"></span>
                                </label>
                            </div>

                            <div className='setting_content_row'>
                                <p className='settings_row_title'>Notifications</p>
                                <label className="switch">
                                    <input type="checkbox" onChange={changeNotificationSettings} id="notification_switch"/>
                                    <span className="slider round"></span>
                                </label>
                            </div>

                            <hr className='setting_content_hr'/>
                        </div>
                    </div>
                    <div className='settings_content_element'>
                        <p className='setting_content_title'>Data privacy</p>

                        <div className='setting_content_block'>
                            <div className='setting_content_row'>
                                <p className='settings_row_title'>Copyright</p>
                                <p className='settings_row_text'>All rights reserved</p>
                            </div>
                        </div>
                    </div>
                </div>
                
    
            </div>
            
        </div>
    )
}

export default Settings;