import React, {  useState } from 'react';


const Signin = () =>{

    const [error_message, setError_message] = useState("")

    const [second_error_message, setSecond_error_message] = useState("")

    const [email, setEmail] = useState("belojevicdamjan@gmail.com")

    const [password, setPassword] = useState("dambeldev")


    const handleEmailChange  = (event) =>{
        setEmail(event.target.value)
    }
    

    const handlePasswordChange  = (event) =>{
        setPassword(event.target.value)
    }

    const checkSign = () =>{
        if(document.getElementById("signup_email").value !== "belojevicdamjan@gmail.com"){

            setError_message("Wrong email address")

            setSecond_error_message("(Pssst, it's belojevicdamjan@gmail.com)")

            

        }else{
            if(document.getElementById("signup_password").value !== "dambeldev"){

                setError_message("Wrong password")

                setSecond_error_message("(Pssst, it's dambeldev)")

            }else{
                window.location.href = "/";
            }
            
        }
    }


    return(
        <div className="login_wrapper">
           <div className="sign_block">
                <div className="sign_header">
                    <img className="logo_img sign_image" src="/img/logo.webp" alt="Logo"/>
                    <p className="sign_title">Sign In</p>
                </div>
                <div className="sign_body">
                    <div className="signup_element">
                        <label className="sign_label">
                            Email
                        </label> 
                        <input type="text" className="signup_input" id="signup_email" value={email} onChange={handleEmailChange }/>
                    </div>
                    <div className="signup_element second_sign_element">
                        <label className="sign_label">
                            Password
                        </label> 
                        <input type="password" className="signup_input" id="signup_password" value={password} onChange={handlePasswordChange }/>
                    </div>

                    <p className='sign_error'>{error_message}</p>
                    <p className='sign_error second_error'>{second_error_message}</p>
                    
                    

                    <div className="sign_button_wrapper">
                        <button className="sign_button" id="sign_button" onClick={() => checkSign()}>Sign In</button>
                    </div>
                        
                </div>
           </div>
        </div>
    )
}

export default Signin;