import { useNavigate } from 'react-router-dom';

const Project = (props) => {

    const navigate = useNavigate();

    

    const redirect = () =>{

        let location = window.location.pathname;

        document.documentElement.style.scrollBehavior = 'auto';

        if(location === "/"){
            setTimeout(()=>{
                navigate(props.url_path + "?page=main");
            }, 0)
            
        }else{
            setTimeout(()=>{
                navigate(props.url_path + "?page=projects");
            }, 0)
            
        }

       
    }


    return (
        <div className="experience_content" id="experience_content">
               <div id={props.id} className="scroll_project">

               </div>
               
                    <div className="ex_main" >
                        <div className="ex_logo_wrapper project_img_wrap">
                            <img src={props.image} alt={props.title}/>
                        </div>
                        <div className="ex_main_info">
                            <p onClick={() => redirect()} className="ex_title">{props.title}</p>

                            <p className="ex_body_element ex_pos">{props.position}</p>
                        
                            <p className="ex_title_info">{props.date} {props.year}</p>
                        
                            <p className="ex_title_info">{props.location}</p>
                        </div>

                    </div>

                    <div className="ex_body pro_body">

                        <p className="ex_body_element">
                            <b>Description: </b> {props.description}
                        </p>

                        <p className="ex_body_element">
                            <b>Skills: </b> {props.skills}
                        </p>
                    </div>

                </div>
    )
}

export default Project;