

const Languages = () =>{
    return(
        <div className="main_section about_section relative_section">

            
            <div className="about_wrapper">
                <p className="section_title">Languages</p>
                    <div className="languages_wrapper">
                        <p className="language_title">
                            English
                        </p>
                        <p className="language_proficiency">
                            Full professional proficiency
                        </p>

                        <hr className="projects_hr languages_hr"/>

                        <p className="language_title">
                            Serbian
                        </p>
                        <p className="language_proficiency">
                            Native or bilingual proficiency
                        </p>

                    </div>
            </div>

            
        </div>
    )
}

export default Languages;