import { useLocation, useNavigate, useParams } from 'react-router-dom';
import data from '../../../../data.json';
import { useEffect } from 'react';


const SingleProject = () =>{

    

    const navigate = useNavigate();

    const { projectUrl } = useParams();

    const location = useLocation();

    const foundProject = data.projects.find(project =>
        project.url_path === projectUrl
    );

    useEffect(()=>{
        document.getElementById("p_about_content").innerHTML = foundProject.about;
        scrollToTop();
    })


    if (!foundProject) {
        setTimeout(() => navigate('/'), 0);
        return;
    }

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            left: 0
        });
    }


    //Slider
    let slider_index = 0;

    const smallPreviewEvent = (index ) => {
        let main_preview = document.getElementById("main_preview");
        main_preview.src = document.getElementsByClassName("preview_img")[index].src;
        main_preview.style.width = "100%";
        main_preview.style.height = "auto";
        slider_index = index;
        focusButton(index)
    }

    

    const focusButton = (index) =>{  
        for(let i = 0; i < document.getElementsByClassName("simage_preview").length; i++){
            if(i !== index){
                document.getElementsByClassName("simage_preview")[i].style.border = "2px solid #c2c2c2";
            }else{
                document.getElementsByClassName("simage_preview")[i].style.border = "2px solid #0070ad";
            }
        }
    }


    const changeSliderRight = (number) =>{
        if(slider_index < 4 ){
            smallPreviewEvent(slider_index + number);
        }

    }

    const changeSliderLeft = (number) =>{

        if(slider_index > 0){
            smallPreviewEvent(slider_index + number);
    
        }

    }

   
    


    return(
        <div className='sp_sec_wrapper'>

        
        <div className="main_section sing_project_section">
        <div className="skill_title_wrapper">
            <div className="skill_title_wrapper back_single_project">
                    <div className="back_skills" onClick={ ()=> 
                    
                    { 
                        const queryParams = new URLSearchParams(location.search);
                        const pageValue = queryParams.get('page');
                        if(pageValue === "main"){
                            navigate("/#" + foundProject.id)
                        }else{
                            navigate("/work#" + foundProject.id)
                        }

                    }
                    }>
                        <span className="fa-solid fa-arrow-left"></span>
                    </div>
         

            </div>
             </div>
            <div className="sproject_wrapper">
                
                <div className="project_left">
                    <div className="p_image_wrapper">
                        <img id="main_preview" src={foundProject.images[0]} alt="Project"/>
                        <div className="preview_button_wrapper">
                            <div className="preview_block">
                                <div id="p_left_arrow" className="arrow_preview p_left_arrow" onClick={() =>changeSliderLeft(-1)}>
                                    <span className="fa-solid fa-arrow-left"></span>
                                </div>
                                <div id="p_right_arrow" className="arrow_preview p_right_arrow" onClick={() =>changeSliderRight(1)}>
                                <span className="fa-solid fa-arrow-right"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="simage_previews">
                        <div className="simage_preview" onClick={() => smallPreviewEvent(0)}>
                            <img className="preview_img" src={foundProject.images[0]} alt="Project"/>
                        </div>
                        <div className="simage_preview" onClick={() => smallPreviewEvent(1)}>
                            <img className="preview_img" src={foundProject.images[1]} alt="Project"/>
                        </div>
                        <div className="simage_preview" onClick={() => smallPreviewEvent(2)}>
                            <img className="preview_img" src={foundProject.images[2]} alt="Project"/>
                        </div>
                        <div className="simage_preview" onClick={() => smallPreviewEvent(3)}>
                            <img className="preview_img" src={foundProject.images[3]} alt="Project"/>
                        </div>
                        <div className="simage_preview" onClick={() => smallPreviewEvent(4)}>
                            <img className="preview_img" src={foundProject.images[4]} alt="Project"/>
                        </div>
                    </div>
                    
                </div>
                <div className="project_right">
                    <h1 className="p_project_title">{foundProject.title}</h1>
                    <hr className="p_hr"/>
                    
                    <p className="p_detail_element">
                        <span className="p_detail_title">Status: </span> <span className="p_status" style={{ color: foundProject.status === 'ACTIVE' ? 'var(--tab_button_selected)' : 'red' }}>{foundProject.status}</span>
                    </p>

                    <p className="p_detail_element">
                        <span className="p_detail_title">Role: </span> <span className="p_basic">{foundProject.role.join(", ")}</span>
                    </p>
                    <p className="p_detail_element">
                        <span className="p_detail_title">Skills/Technologies: </span><span className="p_basic">{foundProject.skills.join(", ")}</span>
                    </p>
                    <p className='p_detail_element' id="source_element">
                        {foundProject.link ? 
                            <span><span className='p_detail_title'>Link: </span> <span className='p_basic'> <a className='p_link' href={foundProject.link} target='_blank' rel="noreferrer">{foundProject.title}</a></span></span>
                            :
                            <span><span className='p_detail_title'>Source code: </span> <span className='p_basic'> <a className='p_link' href={foundProject.source} target='_blank' rel="noreferrer">Github</a></span></span>
                        }
                    </p>
                </div>
            </div>
            <div className="about_wrapper sp_about">
                <h2 className="p_about_title">About the project</h2>

                <p className="p_about_content" id="p_about_content"></p>
            </div>

        </div>
</div>
    )

}

export default SingleProject