import { useCallback, useContext, useEffect, useState } from "react";
import { RemoveDeskNotNumberContext, RemoveDesktopNotification, ShowNotContext } from "../../../../../App";


const Notifications = () =>{

    const [showNot, setShowNot] = useContext(ShowNotContext);

    const [removeDeskNot, setRemoveDeskNot] = useContext(RemoveDesktopNotification);

    const removeDeskNotNumberFunCall = useContext(RemoveDeskNotNumberContext)


    const showHideNot = () =>{
        setShowNot(!showNot);
    }

    const preventDef = (event) =>{
        event.preventDefault();
        event.stopPropagation();
    }

    const [showNotContent, setShowNotContent] = useState(false);

    const showNotificationsNumber  = useCallback(() => {
        let not_info = document.getElementById("not_day_value");
        if(!localStorage.getItem("clear_not") && removeDeskNot){
            not_info.textContent = localStorage.getItem("todays_not");
        }
            
    }, [removeDeskNot]);

    useEffect(()=>{

        setTimeout(()=> showNotificationsNumber(), 0)

            if(localStorage.getItem("pageview_date")){
                if(localStorage.getItem("clear_not")){
                    setShowNotContent(false)
                }else{
                    setShowNotContent(true)
                    
                }

            }else{
                setTimeout(()=>{ setShowNotContent(true)}, 10000)
            }

    }, [showNotContent, showNotificationsNumber])    


    const clearNotifications = () =>{
        localStorage.setItem("clear_not", "true")
        setShowNotContent(false);
        setRemoveDeskNot(!removeDeskNot);
        removeDeskNotNumberFunCall(false)
    }

    

    return(
            <div className="settings_wrapper mobile_notifications" onClick={preventDef}>
                <div className='settings_block mobile_not_block' >
                    <div className='settings_header'>
                        <div>
                            <p className='popup_title'>Notifications</p>
                        </div>
                        <div>
                            <button className='close_popup' onClick={() => showHideNot()}><span className="fa-solid fa-xmark"></span></button>
                        </div>
                    </div>

                    <div className='settings_content'>
                        <div className='settings_content_element'>

                        {showNotContent ? 

                        <div className="mobile_not_wrapper">
                            <div className='not_wrapper not_wrapper_mob'>
                                <div className='not_profile not_profile_mob'>
                                    <img src="/img/profile.webp" alt="Damjan profile"/>
                                </div>
                                <div className='not_cont_wrapper not_cont_wrapper_mob'>
                                    <p className='not_content_text not_content_text_mob'>Somebody viewed your profile!</p>
                                    <p className='not_content_text not_content_text_mob'>You got <b><span id="not_day_value"></span> new view(s)</b> today</p>    
                                </div>
                            </div>

                            <hr className='setting_content_hr'/>

                            <div className="mobile_not_button">
                                <button className="clear_not_button" onClick={() => clearNotifications()}>Clear Notifications</button>
                            </div>

                            
                        </div>
                           

                            :

                            <p className='no_not_content no_not_content_mob'>No new notifications</p>
                        }
                           

                           
                            
                        </div>
                    </div>
                </div>
            </div>
    )
    
        
}

export default Notifications;