

const Panel = () => {


    const checkSign = async () => {
        const email = document.getElementById('signup_email').value;
        const password = document.getElementById('signup_password').value;

    fetch('https://backend.dambel.dev/api/user', {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
    })
    .then(response => {
        if (!response.ok) {
            throw response;
        }
        return response.json();
    })
    .then(data => {
        console.log(data.user);
        window.location.href = "/panel/admin";
    })
    .catch(async (errorResponse) => {
        const error = await errorResponse.json();
        alert(error.message); 
    });
    };

    return (
        <div className="login_wrapper">
           <div className="sign_block">
                <div className="sign_header">
                    <img className="logo_img sign_image" src="/img/logo.webp" alt="Logo"/>
                    <p className="sign_title">Sign In</p>
                </div>
            <div className="sign_body">
                <form onSubmit={(e) => { e.preventDefault(); checkSign(); }}>
                    <div className="signup_element">
      
                        <input type="text" className="signup_input" id="signup_email"  />
                    </div>
                    <div className="signup_element second_sign_element">
                        
                        <input type="password" className="signup_input" id="signup_password" />
                    </div>

                    <div className="sign_button_wrapper">
                        <button type="submit" className="sign_button" id="sign_button">Sign In</button>
                    </div>
                </form>  
            </div>
            </div>
        </div>
    );
}

export default Panel;