import { useEffect } from "react";
import Interest from "./interest/Interest";



const Interests = () => {


    useEffect(() => {
        selectInterest(1);
    })


    const selectInterest = (interest_number) =>{
        if(interest_number === 1){
            document.querySelector("#first_button").style.color = "var(--tab_button_selected)";
            document.querySelector("#first_button").style.borderBottom = "2px solid var(--tab_button_selected)";

            document.querySelector("#second_button").style.color = "var(--profile_image_border)";
            document.querySelector("#second_button").style.borderBottom = "2px solid var(--header_background)";

            document.querySelector("#interests_companies").style.display = "block";
            document.querySelector("#interests_schools").style.display = "none";

        }else{
            document.querySelector("#first_button").style.color = "var(--profile_image_border)";
            document.querySelector("#first_button").style.borderBottom = "2px solid var(--header_background)";


            document.querySelector("#second_button").style.color = "var(--tab_button_selected)";
            document.querySelector("#second_button").style.borderBottom = "2px solid var(--tab_button_selected)";

            document.querySelector("#interests_companies").style.display = "none";
            document.querySelector("#interests_schools").style.display = "block";

        }
    }




    return(
        <div className="main_section about_section relative_section">
            
            <div className="interests_wrapper">
                <p className="section_title">Interests</p>
                    
            </div>
                        
                <div className="interests_tabs">
                    <div className="interests_tab">
                        <button className="tab_button" id="first_button" onClick={() => selectInterest(1)}>
                            Companies   
                        </button>
                        
                    </div>
                    <div className="interests_tab">
                        <button className="tab_button" id="second_button" onClick={() =>selectInterest(2)}>
                             Schools
                        </button>
                    </div>
                </div>

            <div className="interests_wrapper_second">
                
                <div className="interests_companies" id="interests_companies">
                    <div className="interests_row">
                        <Interest img={"img/klaviyo.webp"} title={"Klaviyo"} follow={"82,277 followers"} link={"https://www.klaviyo.com/"}/>

                        <Interest img={"img/react.webp"} title={"ReactJS"} follow={"73,121 followers"} link={"https://react.dev/"}/>
                    </div>

                    <div className="interests_row other_interests_row">
                        <Interest img={"img/js.webp"} title={"JavaScript"} follow={"668,569 followers"} link={"https://www.javascript.com/"}/>

                        <Interest img={"img/angular.webp"} title={"Angular"} follow={"37,988 followers"} link={"https://angular.io/"}/>
                    </div>
                    
                </div>
                <div className="interests_companies" id="interests_schools">
                    <div className="interests_row">
                        <Interest img={"img/singi.webp"} title={"Singidunum University"} follow={"14,120 followers"} link={"https://eng.singidunum.ac.rs/"}/>
                    </div>

                    
                </div>
                    
            </div>
            
        </div>
    )
}

export default Interests;