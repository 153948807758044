import { useEffect, useState } from "react";
import Skill from "./skill/Skill";
import { useNavigate } from 'react-router-dom';


const Skills = (props) =>{

    const [showSkills, setshowSkills] = useState(false);

    const navigate = useNavigate();


    useEffect(()=>{
        if(props.showall === true){
            setshowSkills(true)
            setTimeout(() => scrollToTop(), 200);
            
        }else{
            setshowSkills(false)
    
        }
    },[props.showall])


    const skillStyle = {
        marginTop: '80px'
    };

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    return (
        <div className='margin_around'>
        <div className="main_section about_section relative_section" style={!showSkills ? undefined : skillStyle}>

            <div id="skills">

            </div>
            
            <div className="about_wrapper">

            <div className="skill_title_wrapper">
                {showSkills &&
                    <div className="back_skills" onClick={ ()=> 
                    { 
                        navigate('/')
                        props.backToSkills()
                    }
                    }>
                        <span className="fa-solid fa-arrow-left"></span>
                    </div>
                }
                <p className="section_title">Skills</p>
            </div>
                

                <div className="experience_content" id="experience_content">
               
               
                   <Skill skill={"JavaScript"} endorsements={52}/>

                   <hr className="projects_hr"/>

                   <Skill skill={"Klaviyo"} endorsements={40}/>

                   <hr className="projects_hr"/>

                   <Skill skill={"React"} endorsements={37}/>

                   {showSkills &&
                   <div>
                        <hr className="projects_hr"/>

                        <Skill skill={"AMP"} endorsements={21}/>

                        <hr className="projects_hr"/>

                        <Skill skill={"Angular.js"} endorsements={56}/>

                        <hr className="projects_hr"/>

                        <Skill skill={"PHP"} endorsements={17}/>


                        <hr className="projects_hr"/>

                        <Skill skill={"Next.js"} endorsements={17}/>

                        <hr className="projects_hr"/>

                        <Skill skill={"Webflow"} endorsements={17}/>

                        <hr className="projects_hr"/>

                        <Skill skill={"MySQL"} endorsements={43}/>

                        <hr className="projects_hr"/>

                        <Skill skill={"Node.js"} endorsements={44}/>

                        <hr className="projects_hr"/>

                        <Skill skill={"MongoDB"} endorsements={19}/>

                        <hr className="projects_hr"/>

                        <Skill skill={"HTML5"} endorsements={52}/>

                        <hr className="projects_hr"/>

                        <Skill skill={"CSS3"} endorsements={59}/>
                   </div>
                    
                   }
                   

                </div>
                
            </div>
            {!showSkills &&
                <div className="all_projects" onClick={() =>  navigate('/skills')}>
                    <p>Show all 13 Skills <span className="fa-solid fa-arrow-right"></span></p>
                </div>
            }
            
        </div>
        </div>
    )
}

export default Skills;