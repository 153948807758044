
import { forwardRef, useImperativeHandle } from "react";
import Logo from "./logo/Logo";
import Nav from "./nav/Nav";
import Profile from "./profile/Profile";
import Search from "./search/Search";

const Header = forwardRef(({ navRef,profRef,remFanRef, ...props }, ref) => {


    useImperativeHandle(ref, () => ({
        successToast: (message, success_code) => {
            successToast(message, success_code);
        }
    }));



    const successToast = (message, success_code) =>{

        //Get toast wrapper
        let toast_wrapper = document.getElementsByClassName("toast_wrapper")[0];

            //Add the button
            let toastElement = document.createElement('div');
            toastElement.classList.add("toast_button");

            //Add toast message
            let toastMessage = document.createElement('div');
            toastMessage.classList.add("toast_message");
            if(success_code === "success"){
                toastMessage.innerHTML = '<span class="fa-solid fa-circle-check green_check"></span>' + message
            }else{
                toastMessage.innerHTML = '<span class="fa-solid fa-circle-xmark red_check"></span>' + message
            }
            

            //Add toast close
            let toastClose = document.createElement('div');
            toastClose.classList.add("toast_close");
            toastClose.innerHTML = '<span class="fa-solid fa-xmark"></span>'

            //Add all the elements
            toastElement.appendChild(toastMessage);
            toastElement.appendChild(toastClose);
            toast_wrapper.appendChild(toastElement);

            //Add animation
            setTimeout(()=>{
                toastElement.style.top = "130px";
                toastElement.style.opacity = "1";
            }, 100)

            //Change opacity after 1 second
            setTimeout(()=>{
                toastElement.style.opacity = "0";
            }, 1500)

            //Display none element 1 second after showing
            setTimeout(()=>{
                toastElement.style.display = "none";
            }, 2500)

            //Close toast notificarion
            closeToast();
    }


    //Set Close toast function
    const closeToast = ()=>{
        document.querySelectorAll(".toast_close").forEach((closeToast, index) =>{
            closeToast.addEventListener("click", ()=>{
                document.getElementsByClassName("toast_button")[index].style.display = "none";
            })
        })
    }


    return (
        <div className="section_header">
            <div className="header_wrapper">
                <div className="logo_search_wrapper">
                    <Logo/>
                    <Search/>
                </div>
                <div className="nav_profile_wrapper">
                    <Nav ref={navRef} remFanRef={remFanRef}/>
                    <Profile ref={profRef}/>
                </div>
            </div>

            <div className="toast_wrapper">
                
            </div>
        </div>
    )
});

export default Header;