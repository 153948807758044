const Interest = (props) => {


    return(
        <div className="interest_wrapper">
            
            <div className="interest_main">
                <div className="interest_image_wrapper">
                    <img src={props.img} alt={props.title}/>
                </div>
                <div className="interest_info">
                    <a href={props.link}  rel="noreferrer" target="_blank" className="interest_title">{props.title}</a>
                    <p className="interest_follow">{props.follow}</p>
                </div>
            </div>
            <div className="interest_follow_button">
                <p><span className="fa-solid fa-check"></span>&nbsp;&nbsp;Following</p>
            </div>
            
            
        </div>
    )
}

export default Interest;