
import { useNavigate } from 'react-router-dom';

const Logo = () =>{

    const navigate = useNavigate();

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    return (
        <div className="logo">
            <div onClick={() => {

                navigate("/")
                scrollToTop();

                }
                }>
                <img className="logo_img" src="/img/logo.webp" alt="Dambel Logo"/>
            </div>
        </div>
    )
};

export default Logo;