


const Footer = () =>{

    const current_year = new Date().getFullYear();

    return(
        <div className="footer">
            <footer>
                Dambel Portfolio &copy; {current_year}
            </footer>
        </div>
    )
}

export default Footer;